import React, { ElementType } from 'react';
import cx from 'classnames';
import { ButtonPadding, ButtonPlacement, ButtonTypes } from './Button.types';

type Props = {
  variantType?: ButtonTypes;
  placement?: ButtonPlacement;
  padding?: ButtonPadding;
  as?: ElementType;
  reverse?: boolean;
  [x: string]: any;
};

const Button: React.FC<Props> = ({
  children,
  as: AS = 'button',
  variantType = ButtonTypes.Primary,
  placement = ButtonPlacement.CENTER,
  padding = ButtonPadding.DEFAULT,
  reverse = false,
  className,
  ...otherProps
}) => {
  return (
    <AS
      {...otherProps}
      className={cx(
        className,
        placement,
        padding,
        'whitespace-no-wrap inline-flex border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none focus:shadow-outline-indigo transition ease-in-out duration-150 select-none font-secondary gap-4',
        {
          'text-white bg-br-1-600 hover:bg-br-1-700 focus:bg-br-1-700 active:bg-br-1-700':
            variantType === ButtonTypes.Primary && !reverse,
          'text-white bg-br-2-600 hover:bg-br-2-700 text-br-2-700 focus:border-br-2-700 active:bg-br-2-700':
            variantType === ButtonTypes.Secondary && !reverse,
          'text-white bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700':
            variantType === ButtonTypes.Success && !reverse,
          'text-white bg-red-600 hover:bg-red-500 focus:border-red-700 active:bg-red-700':
            variantType === ButtonTypes.Danger && !reverse,
          'text-white bg-teal-600 hover:bg-teal-500 focus:border-teal-700 active:bg-teal-700':
            variantType === ButtonTypes.Info && !reverse,
          'text-br-1-600 bg-white hover:text-br-1-500 focus:text-br-1-500 active:text-br-1-500':
            variantType === ButtonTypes.Primary && reverse,
          'text-br-2-600 bg-white hover:text-br-2-500 focus:text-br-2-500 active:text-br-2-500':
            variantType === ButtonTypes.Secondary && reverse,
          'text-green-600 bg-white hover:text-green-400 focus:text-green-400 active:text-green-400':
            variantType === ButtonTypes.Success && reverse,
          'text-red-600 bg-white hover:text-red-500 focus:text-red-500 active:text-red-500':
            variantType === ButtonTypes.Danger && reverse,
          'text-teal-600 bg-white hover:text-teal-500 focus:text-teal-500 active:text-teal-500':
            variantType === ButtonTypes.Info && reverse,
          'text-br-1-600 hover:text-br-1-500 focus:text-br-1-500 active:text-br-1-500':
            variantType === ButtonTypes.Nude,
        }
      )}
    >
      {children}
    </AS>
  );
};

export default Button;
