export enum ButtonTypes {
  Primary,
  Secondary,
  Success,
  Danger,
  Info,
  Nude,
}

export enum ButtonPlacement {
  CENTER = 'items-center justify-center',
  START = 'items-start justify-start',
  END = 'items-end justify-end',
  NONE = '',
}

export enum ButtonPadding {
  DEFAULT = 'px-4 py-2',
  NONE = '',
}
