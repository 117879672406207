import React, { FC } from 'react';
import cx from 'classnames';
import { TextFontSize, TextType, TextVariant } from './Text.types';

type Props = {
  className?: string;
  variant?: TextVariant;
  type?: TextType;
  size?: TextFontSize;
  colorClass?: string;
};

const Text: FC<Props> = ({
  className = '',
  type = TextType.SECONDARY,
  variant,
  children,
  colorClass,
  size = TextFontSize.BASE,
}) => (
  <p
    className={cx(
      size,
      'leading-6',
      {
        'text-black dark:text-white': !colorClass,
        [`${colorClass}`]: colorClass,
      },
      type,
      className,
      variant
    )}
  >
    {children}
  </p>
);

export default Text;
