import React, { FC } from 'react';

export const SmallSpace: FC = ({ children }) => {
  return <div className="mx-2">{children}</div>;
};

export const Separator: FC = ({ children }) => {
  return <div className="m-4">{children}</div>;
};

export const SmallSeparator: FC = ({ children }) => {
  return <div className="m-2">{children}</div>;
};

export const Head: FC = ({ children }) => (
  <div className="flex flex-1 flex-col justify-center items-center">
    {children}
  </div>
);

export const ContentRow: FC = ({ children }) => (
  <div className="flex flex-1 flex-col md:flex-row">{children}</div>
);

export const ContentWrap: FC = ({ children }) => (
  <div className="flex flex-1 flex-wrap md:flex-row justify-center items-center">
    {children}
  </div>
);

export const ContentCol: FC = ({ children }) => (
  <div className="flex flex-1 flex-col">{children}</div>
);

export const CustomLayout: FC & {
  Head: FC;
  Row: FC;
  Col: FC;
  Separator: FC;
} = ({ children }) => (
  <div className="flex flex-1 flex-col w-full">{children}</div>
);

CustomLayout.Head = Head;
CustomLayout.Row = ContentRow;
CustomLayout.Col = ContentCol;
CustomLayout.Separator = Separator;

export default CustomLayout;
