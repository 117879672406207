import React, { FC } from 'react';
import cx from 'classnames';
import { SectionSize, SectionType } from './Section.types';

type Props = {
  className?: string;
  type: SectionType;
  size?: SectionSize;
};

const Section: FC<Props> = ({
  className = '',
  type,
  children,
  size = SectionSize.LARGE,
}) => (
  <section
    className={cx(
      'w-full flex flex-1 justify-center items-center p-4 lg:p-8',
      {
        'bg-white dark:bg-black': type === SectionType.Primary,
        'bg-lighter-1 dark:bg-darker-1': type === SectionType.Secondary,
        'bg-lighter-2 dark:bg-darker-2': type === SectionType.Tertiary,
        'bg-lighter-3 dark:bg-darker-3': type === SectionType.Quaternary,
      },
      className
    )}
  >
    <div
      className={cx(
        'flex flex-1 w-full flex-col justify-center items-center',
        size
      )}
    >
      {children}
    </div>
  </section>
);

export default Section;
