import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import ButtonGroup from '@kastle/ui/src/atoms/Button/ButtonGroup';
import Button from '@kastle/ui/src/atoms/Button/Button';

type Props = {
  to: string;
  label: string;
};

const ButtonLink: FC<Props> = ({ to, label, children, ...props }) => (
  <ButtonGroup>
    <Button
      as={GatsbyLink}
      to={to}
      style={{ textDecoration: 'none' }}
      aria-label={label}
      {...props}
    >
      {children}
    </Button>
  </ButtonGroup>
);

export default ButtonLink;
