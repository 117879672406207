export enum SectionType {
  Primary,
  Secondary,
  Tertiary,
  Quaternary,
}

export enum SectionSize {
  LARGE = 'Section__container__large',
  MEDIUM = 'Section__container__medium',
  SMALL = 'Section__container__small',
}
