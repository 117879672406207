import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Props = {
  image: IGatsbyImageData;
  alt?: string;
};

const FullImage: FC<Props> = ({ image, alt }) => {
  return (
    <GatsbyImage
      image={image}
      style={{ height: '100%', width: '100%' }}
      objectFit="contain"
      alt={alt}
    />
  );
};

export default FullImage;
