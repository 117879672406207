import React, { FC } from 'react';
import {
  FaAngular,
  FaApple,
  FaAppStore,
  FaAws,
  FaBitcoin,
  FaCss3Alt,
  FaDocker,
  FaGit,
  FaGoogle,
  FaHtml5,
  FaReact,
} from 'react-icons/fa';
import {
  AutomaticSlider,
  ItemSizes,
  ListSize,
  SliderLayout,
} from '@kastle/ui/src/molecules/Slider/AutomaticSlider';
import { SliderTypes } from '@kastle/ui/src/molecules/Slider/Slider.types';

type TechnologiesProps = {
  className?: string;
};

export const Technologies: FC<TechnologiesProps> = ({ className }) => {
  return (
    <AutomaticSlider
      type={SliderTypes.Secondary}
      layouts={[SliderLayout.CENTERED, SliderLayout.ALMOST_FULL]}
      className={className}
    >
      <AutomaticSlider.List className={ListSize.BIG}>
        <FaReact className={ItemSizes.SMALL} />
        <FaAngular className={ItemSizes.SMALL} />
        <FaGit className={ItemSizes.SMALL} />
        <FaAws className={ItemSizes.SMALL} />
        <FaAppStore className={ItemSizes.SMALL} />
        <FaBitcoin className={ItemSizes.SMALL} />
        <FaCss3Alt className={ItemSizes.SMALL} />
        <FaHtml5 className={ItemSizes.SMALL} />
        <FaDocker className={ItemSizes.SMALL} />
        <FaApple className={ItemSizes.SMALL} />
        <FaGoogle className={ItemSizes.SMALL} />
      </AutomaticSlider.List>
    </AutomaticSlider>
  );
};
