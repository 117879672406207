import React, { FC } from 'react';
import cx from 'classnames';
import { IconTypes } from './Icon.types';

type Props = {
  Component: FC<React.SVGProps<SVGSVGElement>>;
  variant?: IconTypes;
} & React.SVGProps<SVGSVGElement>;

const Icon: FC<Props> = ({ Component, variant, className, ...props }) => (
  <Component
    className={cx(
      'fill-current pointer-events-none select-none',
      {
        'text-black': variant === IconTypes.Dark,
        'text-white': variant === IconTypes.Light,
        'text-br-1-600': variant === IconTypes.Primary,
        'text-br-2-600': variant === IconTypes.Secondary,
      },
      className
    )}
    {...props}
  />
);

export const withSmallIcon = (Component: React.FC): React.FC => {
  const SmallIconComponent: React.FC = () => (
    <Icon Component={Component} className="text-br-1-600 h-6 w-6" />
  );
  return SmallIconComponent;
};

export default Icon;
