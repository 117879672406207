import React from 'react';

const FirstMobileWorkShowcase = React.lazy(() => import('./First'));

export const First = () => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <FirstMobileWorkShowcase />
        </React.Suspense>
      )}
    </>
  );
};

const SecondMobileWorkShowcase = React.lazy(() => import('./Second'));

export const Second = () => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <SecondMobileWorkShowcase />
        </React.Suspense>
      )}
    </>
  );
};
