import React, { ElementType, FC } from 'react';
import cx from 'classnames';
import { LabelTypes } from './Label.types';

type Props<T = ElementType> = {
  as?: T;
  variantType?: LabelTypes;
  className?: string;
  [key: string]: any;
};

const Label: FC<Props> = ({
  as: AS = 'p',
  children,
  variantType = LabelTypes.Primary,
  className,
  ...otherProps
}) => {
  return (
    <AS
      className={cx(
        'text-base leading-6 font-semibold tracking-wide uppercase font-secondary',
        {
          'text-br-1-600': variantType === LabelTypes.Primary,
          'text-br-2-600': variantType === LabelTypes.Secondary,
        },
        className
      )}
      {...otherProps}
    >
      {children}
    </AS>
  );
};

export default Label;
