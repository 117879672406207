import React, { FC } from 'react';
import cx from 'classnames';
import { SliderTypes } from './Slider.types';

export enum ItemSizes {
  SMALL = 'h-10 w-10 mr-8 ml-8  text-black dark:text-white',
  MEDIUM = 'h-24 w-24 mr-8 ml-8 text-black dark:text-white',
  BIG = 'h-32 w-32 mr-8 ml-8 text-black dark:text-white',
}

type ListProps = {
  className?: string;
};

export enum ListSize {
  SMALL = 'h-10',
  MEDIUM = 'h-24',
  BIG = 'h-32',
}

const List: FC<ListProps> = ({ children, className }) => {
  return (
    <div className={cx(className, 'inline-flex justify-center items-center')}>
      {children}
    </div>
  );
};

export enum SliderLayout {
  ALMOST_FULL = 'w-11/12',
  CENTERED = 'flex justify-center items-center',
}

type AutomaticSliderProps = {
  type: SliderTypes;
  className?: string;
  layouts?: SliderLayout[];
  replicate?: boolean;
};

export const AutomaticSlider: FC<AutomaticSliderProps> & {
  List: FC<ListProps>;
} = ({ children, type, className, layouts, replicate }) => {
  return (
    <div className={cx(layouts)}>
      <div
        className={cx(className, 'AutomaticSlider_Container', {
          'AutomaticSlider_Container_Primary ': type === SliderTypes.Primary,
          'AutomaticSlider_Container_Secondary ':
            type === SliderTypes.Secondary,
          'AutomaticSlider_Container_Tertiary ': type === SliderTypes.Tertiary,
          'AutomaticSlider_Container_Quaternary ':
            type === SliderTypes.Quaternary,
        })}
      >
        <div className="AutomaticSlider_List">{children}</div>
        <div className="AutomaticSlider_List">{children}</div>
        {replicate ? (
          <div className="AutomaticSlider_List">{children}</div>
        ) : null}
      </div>
    </div>
  );
};

AutomaticSlider.List = List;
