import React from 'react';

const ThirdDesktopWorkShowcase = React.lazy(() => import('./Third'));

export const Third = () => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ThirdDesktopWorkShowcase />
        </React.Suspense>
      )}
    </>
  );
};

const SecondDesktopWorkShowcase = React.lazy(() => import('./Second'));

export const Second = () => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <SecondDesktopWorkShowcase />
        </React.Suspense>
      )}
    </>
  );
};

const FirstDesktopWorkShowcase = React.lazy(() => import('./First'));

export const First = () => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <FirstDesktopWorkShowcase />
        </React.Suspense>
      )}
    </>
  );
};
