import { graphql } from 'gatsby';
import { FaHandshake, GiCastle, MdBook, MdMap, FaLaptop, FaPalette } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType, SectionSize } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import { TextFontSize } from '@kastle/ui/src/atoms/Text/Text.types';
import { Clients } from "../../../../src/components/Slider/Clients";
import { Technologies } from "../../../../src/components/Slider/Technologies";
import { FadeInAnimation } from '@kastle/ui/src/atoms/Animation/FadeIn';
import { FeatureSection } from "../../../../src/sections/FeatureSection";
import * as React from 'react';
export default {
  graphql,
  FaHandshake,
  GiCastle,
  MdBook,
  MdMap,
  FaLaptop,
  FaPalette,
  Section,
  SectionType,
  SectionSize,
  FullImage,
  CustomLayout,
  Head,
  ContentRow,
  ContentCol,
  Separator,
  SmallSpace,
  Heading,
  Text,
  TextFontSize,
  Clients,
  Technologies,
  FadeInAnimation,
  FeatureSection,
  React
};