import React, { FC } from 'react';
import {
  AutomaticSlider,
  SliderLayout,
} from '@kastle/ui/src/molecules/Slider/AutomaticSlider';
import { SliderTypes } from '@kastle/ui/src/molecules/Slider/Slider.types';

type ClientsProps = {
  className?: string;
};

const ClientLogos = React.lazy(() => import('./ClientsLogos'));

export const Clients: FC<ClientsProps> = ({ className }) => {
  const isSSR = typeof window === 'undefined';

  return !isSSR ? (
    <React.Suspense fallback={<div />}>
      <AutomaticSlider
        type={SliderTypes.Tertiary}
        layouts={[SliderLayout.CENTERED, SliderLayout.ALMOST_FULL]}
        replicate
        className={className}
      >
        <AutomaticSlider.List>
          <ClientLogos />
        </AutomaticSlider.List>
      </AutomaticSlider>
    </React.Suspense>
  ) : null;
};
