import { withSmallIcon } from '@kastle/ui/src/atoms/Icons/Icon';
import {
  FaHandshake,
  FaLaptop,
  FaListOl,
  FaMobileAlt,
  FaRegLightbulb,
  FaRocket,
  FaSearchDollar,
  FaSearchengin,
  FaStar,
  FaTh,
  FaThList,
} from 'react-icons/fa';

const MobileIcon = withSmallIcon(FaMobileAlt);
const LaptopIcon = withSmallIcon(FaLaptop);
const FirstIcon = withSmallIcon(FaListOl);
const RocketIcon = withSmallIcon(FaRocket);
const ExperienceIcon = withSmallIcon(FaTh);
const InterfaceIcon = withSmallIcon(FaThList);
const StarIcon = withSmallIcon(FaStar);
const SearchIcon = withSmallIcon(FaSearchengin);
const AgileIcon = withSmallIcon(FaSearchDollar);
const RNDIcon = withSmallIcon(FaRegLightbulb);
const Handshake = withSmallIcon(FaHandshake);

enum IconTypes {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  AGILE = 'agile',
  RND = 'rnd',
  FIRST = 'first',
  ROCKET = 'rocket',
  EXPERIENCE = 'experience',
  INTERFACE = 'interface',
  SEARCH = 'search',
  STAR = 'star',
  HANDSHAKE = 'handshake',
}

export const Icons: Partial<Record<IconTypes, JSX.Element>> = {
  [IconTypes.MOBILE]: MobileIcon,
  [IconTypes.DESKTOP]: LaptopIcon,
  [IconTypes.FIRST]: FirstIcon,
  [IconTypes.ROCKET]: RocketIcon,
  [IconTypes.EXPERIENCE]: ExperienceIcon,
  [IconTypes.INTERFACE]: InterfaceIcon,
  [IconTypes.SEARCH]: SearchIcon,
  [IconTypes.STAR]: StarIcon,
  [IconTypes.AGILE]: AgileIcon,
  [IconTypes.RND]: RNDIcon,
  [IconTypes.HANDSHAKE]: Handshake,
};
