import React, { ElementType, FC, ReactNode } from 'react';
import cx from 'classnames';
import Label from '../../atoms/Label/Label';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';
import { HeadingTypes } from '../../atoms/Heading/Heading.types';

type Elements = {
  title: string;
  description: string;
  Icon: ElementType;
  children?: ReactNode;
};

type Props = {
  title: string;
  subTitle: string;
  description: string;
  elements: Elements[];
  headingType?: HeadingTypes;
  className?: string;
};

const Feature: FC<Props> = ({
  title,
  subTitle,
  description,
  elements,
  headingType = HeadingTypes.h2,
  className,
  children,
}) => {
  return (
    <div className={cx('py-12 w-full ', className)}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="lg:text-center w-full">
          <Label>{subTitle}</Label>
          <Heading as={headingType} className="mt-2">
            {title}
          </Heading>
          {children}
          <Text className="mt-4 max-w-2xl text-xl leading-7 lg:mx-auto">
            {description}
          </Text>
        </div>
        <div className="mt-10">
          <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {elements.map(({ title, description, Icon, children }) => (
              <li key={`${title}-${description}`} className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md text-br-1-600">
                      <Icon />
                    </div>
                  </div>
                  <div className="ml-4">
                    <Heading as={HeadingTypes.h4}>{title}</Heading>
                    <Text className="mt-2">{description}</Text>
                    {children}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Feature;
