import React, { FC } from 'react';
import { i18n, TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Feature from '@kastle/ui/src/organisms/Feature/Feature';
import ButtonLink from '../../../components/src/components/Button/ButtonLink';
import { Icons } from './Icons';

type ClientsProps = {
  className?: string;
  scope: string;
};

type GenerateElementsParameters = {
  scope: string;
  i18n: i18n;
  t: TFunction;
  icons: {
    [key: string]: JSX.Element;
  };
};

const generateElements = ({
  scope,
  i18n,
  t,
  icons,
}: GenerateElementsParameters) => {
  let position = 1;
  const list = [];

  while (i18n.exists(`${scope}.elements.${position}`)) {
    const element = `${scope}.elements.${position}`;

    const iconKey = `${element}.icon`;
    const Icon = i18n.exists(iconKey) && icons?.[t(iconKey)];

    const buttonKey = `${element}.button`;
    const children = i18n.exists(buttonKey) ? (
      <ButtonLink to={t(`${buttonKey}.link`)} label={t(`${buttonKey}.label`)}>
        {t(`${buttonKey}.text`)}
      </ButtonLink>
    ) : null;

    list.push({
      title: t(`${element}.title`),
      description: t(`${element}.description`),
      Icon: Icon,
      children,
    });
    position++;
  }

  return list;
};

export const FeatureSection: FC<ClientsProps> = ({
  className,
  scope = 'home.clients',
  children,
}) => {
  const { t, i18n } = useTranslation();

  const subTitleKey = `${scope}.subTitle`;
  const subTitle = i18n.exists(subTitleKey) && t(subTitleKey);

  const buttonKey = `${scope}.button`;
  const button = i18n.exists(buttonKey) ? (
    <ButtonLink to={t(`${buttonKey}.link`)} label={t(`${buttonKey}.label`)}>
      {t(`${buttonKey}.text`)}
    </ButtonLink>
  ) : null;

  return (
    <>
      <Feature
        className={className}
        title={t(`${scope}.title`)}
        subTitle={subTitle}
        description={t(`${scope}.description`)}
        elements={generateElements({ scope, i18n, t, icons: Icons })}
      >
        {children}
      </Feature>
      {button}
    </>
  );
};
