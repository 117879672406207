export enum TextVariant {
  THIN = 'text-thin',
  BOLD = 'text-bold',
}

export enum TextType {
  PRIMARY = 'font-primary',
  SECONDARY = 'font-secondary',
}

export enum TextFontSize {
  XS = 'text-xs',
  SM = 'text-sm',
  BASE = 'text-base',
  LG = 'text-lg',
  XL = 'text-xl',
  XXL = 'text-2xl',
}

export enum TextColor {
  WHITE = 'text-white',
  BLACK = 'text-black',
}
