import React, { Attributes, ElementType } from 'react';
import cx from 'classnames';
import { HeadingTypes } from './Heading.types';

type Props = {
  as?: HeadingTypes;
  className?: string;
  colorClassName?: string;
  props?: Attributes | null;
  reverse?: boolean;
};

const Heading: React.FC<Props> = ({
  as = HeadingTypes.h1,
  className = '',
  reverse = false,
  colorClassName,
  ...props
}) => {
  return React.createElement(as, {
    ...props,
    className: cx(
      'font-primary',
      {
        'text-5xl sm:text-5xl': as === HeadingTypes.h1,
        'text-4xl sm:text-4xl': as === HeadingTypes.h2,
        'text-3xl sm:text-3xl': as === HeadingTypes.h3,
        'text-2xl sm:text-2xl': as === HeadingTypes.h4,
        'text-xl sm:text-xl': as === HeadingTypes.h5,
        'text-l sm:text-l': as === HeadingTypes.h6,
      },
      {
        'text-black dark:text-white': !colorClassName && !reverse,
        'text-white dark:text-black': !colorClassName && reverse,
      },
      className,
      colorClassName
    ),
  });
};

export default Heading;
